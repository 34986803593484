import styled from 'styled-components'
import { Box } from '../Box'

const StyledBottomNav = styled(Box)`
  position: fixed;
  bottom: 0px;
  width: 100%;
  /* padding: 5px 8px; */
  background: ${({ theme }) => theme.colors.primary};
  height: 85px;

  padding-bottom: env(safe-area-inset-bottom);

  html[data-useragent*='TokenPocket_iOS'] & {
    padding-bottom: 45px;
  }
  z-index: 20;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledOtherMenuNav = styled(Box)`
  position: absolute;
  top: -2px;
  right: 0;
  transform: translateY(-100%);
  background-color: var(--primary-color);

  width: 100%;
  max-width: 150px;
  padding: 0;
  overflow: hidden;

  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`

export default StyledBottomNav
